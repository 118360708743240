import React, { useEffect, useRef } from "react"
import _ from "lodash"
import { css } from "emotion"
import { Box, Text } from "rebass"
import io from "socket.io-client"
import QRCode from "qrcode"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { differenceInMilliseconds } from "date-fns"
import TitleScreen from "./assets/img/titlescreen.jpg"
import GameOver from "./assets/img/gameover.png"
import GameEnd from "./assets/img/gameend.png"
import Player from "./assets/img/spaceship.png"
import Enemies from "./assets/img/enemies.png"
import InsertRanking from "./components/insert-ranking"
import Ranking from "./components/ranking"

let me
let game

try {
  me = require("melonjs").me
} catch (e) {}

const Invaders = () => {
  const socket = useRef()
  const [qrCode, setQrCode] = React.useState(0)
  const [isGameLoaded, setIsGameLoaded] = React.useState(0)
  const [keyphrase, setKeyphrase] = React.useState(0)
  const [isPaired, setIsPaired] = React.useState(0)
  const [killedEnemies, setKilledEnemies] = React.useState(0)
  const [startTime, setStartTime] = React.useState(0)
  const [endTime, setEndTime] = React.useState(0)
  const [gameDuration, setGameDuration] = React.useState(0)
  const [showInsertRanking, setShowInsertRanking] = React.useState(false)
  const [showRanking, setShowRanking] = React.useState(false)

  const initGame = ({ onEnemyKilled }) => {
    game = {
      onload: function () {
        if (
          !me.video.init(640, 480, {
            parent: "screen",
            scale: "fit",
          })
        ) {
          alert("Your browser does not support HTML5 canvas.")
          return
        }

        me.loader.preload(game.resources, this.loaded.bind(this))
      },

      loaded: function () {
        this.titleScreen = new game.TitleScreen()
        this.playScreen = new game.PlayScreen()
        this.gameOverScreen = new game.GameOverScreen()
        this.gameEndScreen = new game.GameEndScreen()

        me.state.set(me.state.MENU, this.titleScreen)
        me.state.set(me.state.PLAY, this.playScreen)
        me.state.set(me.state.GAMEOVER, this.gameOverScreen)
        me.state.set(me.state.GAME_END, this.gameEndScreen)

        me.pool.register("player", game.Player)
        me.pool.register("laser", game.Laser)
        me.pool.register("enemy", game.Enemy)

        me.state.change(me.state.MENU)
      },
    }

    game.resources = [
      { name: "player", type: "image", src: Player },
      { name: "enemies", type: "image", src: Enemies },
      { name: "titlescreen", type: "image", src: TitleScreen },
      { name: "gameover", type: "image", src: GameOver },
      { name: "gameend", type: "image", src: GameEnd },
    ]

    game.Player = me.Entity.extend({
      init: function () {
        const image = me.loader.getImage("player")

        this._super(me.Entity, "init", [
          me.game.viewport.width / 2 - image.width / 2,
          me.game.viewport.height - image.height - 20,
          { image, width: 80, height: 61 },
        ])

        this.velx = 450
        this.maxX = me.game.viewport.width - this.width

        this.body.collisionType = me.collision.types.PLAYER_OBJECT
      },

      update: function (time) {
        this._super(me.Entity, "update", [time])
        if (me.input.isKeyPressed("left")) {
          this.pos.x -= (this.velx * time) / 1000
        }

        if (me.input.isKeyPressed("right")) {
          this.pos.x += (this.velx * time) / 1000
        }

        if (me.input.isKeyPressed("shoot")) {
          me.game.world.addChild(
            me.pool.pull(
              "laser",
              this.pos.x + 40 - game.Laser.width,
              this.pos.y - game.Laser.height
            )
          )
        }

        this.pos.x = me.Math.clamp(this.pos.x, 0, this.maxX)

        return true
      },
    })

    game.PlayScreen = me.Stage.extend({
      checkIfLoss: function (y) {
        if (y >= this.player.pos.y) {
          me.state.change(me.state.GAMEOVER)
        }
      },

      onResetEvent: function () {
        me.game.world.addChild(new me.ColorLayer("background", "#222222"), 0)

        this.player = me.pool.pull("player")
        me.game.world.addChild(this.player, 1)

        this.enemyManager = new game.EnemyManager()
        this.enemyManager.createEnemies()
        me.game.world.addChild(this.enemyManager, 2)

        me.input.bindKey(me.input.KEY.LEFT, "left")
        me.input.bindKey(me.input.KEY.RIGHT, "right")
        me.input.bindKey(me.input.KEY.A, "left")
        me.input.bindKey(me.input.KEY.D, "right")
        me.input.bindKey(me.input.KEY.SPACE, "shoot", true)
      },

      onDestroyEvent: function () {
        me.input.unbindKey(me.input.KEY.LEFT)
        me.input.unbindKey(me.input.KEY.RIGHT)
        me.input.unbindKey(me.input.KEY.A)
        me.input.unbindKey(me.input.KEY.D)
        me.input.unbindKey(me.input.KEY.SPACE)
      },
    })

    game.Enemy = me.Entity.extend({
      init: function (x, y) {
        this._super(me.Entity, "init", [
          x,
          y,
          {
            image: "enemies",
            width: 64,
            height: 64,
          },
        ])

        this.chooseShipImage()

        this.body.setVelocity(0, 0)
        this.body.collisionType = me.collision.types.ENEMY_OBJECT
      },

      update: function (time) {
        this._super(me.Entity, "update", [time])

        this.body.update()

        return true
      },

      chooseShipImage: function () {
        var frame = ~~(Math.random() * 3)
        this.renderable.addAnimation("idle", [frame], 1)
        this.renderable.setCurrentAnimation("idle")
      },
    })

    game.EnemyManager = me.Container.extend({
      init: function () {
        this._super(me.Container, "init", [
          0,
          32,
          this.COLS * 64 - 64,
          this.ROWS * 64 - 64,
        ])

        this.COLS = 8
        this.ROWS = 3
        this.vel = 16
      },

      update: function (time) {
        if (this.children.length === 0 && this.createdEnemies) {
          me.state.change(me.state.GAME_END)
          setEndTime(new Date())
        }

        this._super(me.Container, "update", [time])
        this.updateChildBounds()
      },

      createEnemies: function () {
        for (var i = 0; i < this.COLS; i++) {
          for (var j = 0; j < this.ROWS; j++) {
            this.addChild(me.pool.pull("enemy", i * 76, j * 76))
          }
        }

        this.updateChildBounds()

        this.createdEnemies = true
      },

      onActivateEvent: function () {
        var _this = this
        this.timer = me.timer.setInterval(function () {
          var bounds = _this.childBounds

          if (
            (_this.vel > 0 &&
              bounds.right + _this.vel >= me.game.viewport.width) ||
            (_this.vel < 0 && bounds.left + _this.vel <= 0)
          ) {
            _this.vel *= -1
            _this.pos.y += 16
            if (_this.vel > 0) {
              _this.vel += 5
            } else {
              _this.vel -= 5
            }

            game.playScreen.checkIfLoss(bounds.bottom)
          } else {
            _this.pos.x += _this.vel
          }

          _.forEach(_this.children, val => {
            const rand = _.random(0, 100)

            if (rand % 3 !== 0) {
              return
            }

            const canShoot = !_.find(
              _this.children,
              cval => val.pos._x === cval.pos._x && val.pos._y < cval.pos._y
            )

            if (canShoot) {
              me.game.world.addChild(
                me.pool.pull(
                  "laser",
                  val.pos._x + _this.pos.x + 32,
                  val.pos._y + _this.pos.y + 70,
                  50
                )
              )
            }
          })
        }, 1000)
      },

      onDeactivateEvent: function () {
        me.timer.clearInterval(this.timer)
      },

      removeChildNow: function (child) {
        this._super(me.Container, "removeChildNow", [child])
        this.updateChildBounds()
      },
    })

    game.Laser = me.Entity.extend({
      init: function (x, y, velocity) {
        this._super(me.Entity, "init", [
          x,
          y,
          { width: game.Laser.width, height: game.Laser.height },
        ])
        this.z = 5
        this.body.setVelocity(0, velocity || 300)
        this.body.collisionType = me.collision.types.PROJECTILE_OBJECT
        this.renderable = new (me.Renderable.extend({
          init: function () {
            this._super(me.Renderable, "init", [
              0,
              0,
              game.Laser.width,
              game.Laser.height,
            ])
          },
          destroy: function () {},
          draw: function (renderer) {
            var color = renderer.getColor()
            renderer.setColor("#5EFF7E")
            renderer.fillRect(0, 0, this.width, this.height)
            renderer.setColor(color)
          },
        }))()

        this.alwaysUpdate = true
      },

      onCollision: function (res, other) {
        if (other.body.collisionType === me.collision.types.PLAYER_OBJECT) {
          me.state.change(me.state.GAMEOVER)
          return true
        }

        if (other.body.collisionType === me.collision.types.ENEMY_OBJECT) {
          me.game.world.removeChild(this)
          game.playScreen.enemyManager.removeChild(other)

          onEnemyKilled()
          return true
        }
      },

      update: function (time) {
        this.body.vel.y -= (this.body.accel.y * time) / 1000

        if (this.pos.y + this.height <= 0) {
          me.game.world.removeChild(this)
        }

        this.body.update()

        me.collision.check(this)

        return true
      },
    })

    game.Laser.width = 5
    game.Laser.height = 28

    game.TitleScreen = me.Stage.extend({
      onResetEvent: function () {
        const backgroundImage = new me.Sprite(0, 0, {
          image: me.loader.getImage("titlescreen"),
        })

        backgroundImage.anchorPoint.set(0, 0)
        backgroundImage.scale(
          me.game.viewport.width / backgroundImage.width,
          me.game.viewport.height / backgroundImage.height
        )

        me.game.world.addChild(backgroundImage, 1)

        me.input.bindKey(me.input.KEY.ENTER, "enter", true)
        me.input.bindPointer(me.input.pointer.LEFT, me.input.KEY.ENTER)

        this.handler = me.event.subscribe(me.event.KEYDOWN, function (action) {
          if (action === "enter") {
            me.state.change(me.state.PLAY)
            setStartTime(new Date())
            setShowRanking(false)
            setShowInsertRanking(false)
          }
        })
      },

      onDestroyEvent: function () {
        me.input.unbindKey(me.input.KEY.ENTER)
        me.input.unbindPointer(me.input.pointer.LEFT)
        me.event.unsubscribe(this.handler)
      },
    })

    game.GameEndScreen = me.Stage.extend({
      onResetEvent: function () {
        const backgroundImage = new me.Sprite(0, 0, {
          image: me.loader.getImage("gameend"),
        })

        backgroundImage.anchorPoint.set(0, 0)
        backgroundImage.scale(
          me.game.viewport.width / backgroundImage.width,
          me.game.viewport.height / backgroundImage.height
        )

        me.game.world.addChild(backgroundImage, 1)
      },
    })

    game.GameOverScreen = me.Stage.extend({
      onResetEvent: function () {
        const backgroundImage = new me.Sprite(0, 0, {
          image: me.loader.getImage("gameover"),
        })

        backgroundImage.anchorPoint.set(0, 0)
        backgroundImage.scale(
          me.game.viewport.width / backgroundImage.width,
          me.game.viewport.height / backgroundImage.height
        )

        me.game.world.addChild(backgroundImage, 1)
      },
    })
  }

  useEffect(() => {
    if (endTime) {
      setGameDuration(differenceInMilliseconds(endTime, startTime))
      setShowInsertRanking(true)
    }
  }, [endTime])

  useEffect(() => {
    if (
      typeof navigator !== "undefined" &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.location.href = "/invaders/controller"
    }

    socket.current = io(`https://rewave.herokuapp.com`, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 10,
      transports: ["websocket"],
      agent: false,
      upgrade: false,
      rejectUnauthorized: false,
    })

    document.body.style.backgroundColor = "#1a1818"
    document.body.style.minHeight = "100vh"
    document.body.style.display = "flex"
    document.body.style.flexDirection = "column"
    document.body.style.alignItems = "center"

    initGame({
      onEnemyKilled: () => setKilledEnemies(val => val + 1),
    })

    me.device.onReady(() => {
      game.onload()
      setIsGameLoaded(true)
    })

    socket.current.on("keyphrase", async data => {
      setKeyphrase(data)

      try {
        const qr = await QRCode.toDataURL(
          `${window.location.origin}/invaders/controller/?code=${data}`,
          { width: 400 }
        )

        setQrCode(qr)
      } catch (e) {}
    })

    socket.current.on("paired", () => {
      setIsPaired(true)
    })

    socket.current.on("keyDown", data => {
      me.input.triggerKeyEvent(me.input.KEY[data], true)

      if (
        (me.state.isCurrent(me.state.GAMEOVER) ||
          me.state.isCurrent(me.state.GAME_END)) &&
        data === "ENTER"
      ) {
        me.state.change(me.state.PLAY)
        setStartTime(new Date())
      }
    })

    socket.current.on("keyUp", data => {
      me.input.triggerKeyEvent(me.input.KEY[data], false)
    })
  }, [])

  useEffect(() => {
    console.log("isPaired", isPaired, "keyphrase", keyphrase)
  }, [isPaired, keyphrase])

  if (!isGameLoaded) {
    return <div />
  }

  return (
    <div
      className={css`
        background-color: #1a1818;
      `}
    >
      <Box
        sx={{
          position: "fixed",
          top: "20px",
          right: "40px",
          fontSize: 3,
          zIndex: 111,
        }}
      >
        <a href="/">
          <FontAwesomeIcon icon={faTimes} color="#fff" />
        </a>
      </Box>

      <button
        onClick={() => setShowRanking(true)}
        className={css`
          position: fixed;
          top: 20px;
          left: 20px;
          background-color: #777;
          color: #fff;
          font-weight: 700;
          padding: 7px 10px;
          opacity: 0.8;
        `}
      >
        Rankings
      </button>

      {showRanking && <Ranking close={() => setShowRanking(false)} />}

      {showInsertRanking && (
        <InsertRanking
          score={gameDuration}
          close={() => setShowInsertRanking(false)}
        />
      )}

      {!isPaired && !keyphrase ? (
        <Box
          className={css`
            position: fixed;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #1a1818;
            color: #fff;
            font-family: monospace;
          `}
        >
          Loading
        </Box>
      ) : null}

      {!isPaired && keyphrase ? (
        <Box
          className={css`
            position: fixed;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #1a1818;
            color: #fff;
            font-family: monospace;
          `}
        >
          <Text fontSize={1}>
            Visita questa pagina dal tuo smartphone ed inserisci il codice
            seguente per giocare:
          </Text>

          <Text mt={8} fontSize={5}>
            {keyphrase}
          </Text>

          {qrCode ? (
            <>
              <Text my={8}>oppure scansiona il QR Code:</Text>

              <img
                src={qrCode}
                alt=""
                className={css`
                  flex-shrink: 0;
                  margin-bottom: 32px;
                `}
              />
            </>
          ) : null}
        </Box>
      ) : null}
    </div>
  )
}

export default Invaders
