import React, { useState } from "react"
import { css } from "emotion"
import { Box, Text } from "rebass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const InsertRanking = ({ score, close }) => {
  const [isInserting, setIsInserting] = useState(false)
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")

  const buttonStyle = css`
    font-family: monospace;
    margin: 20px 10px 0;
    padding: 10px 20px;
    background-color: #777;
  `

  const inputStyle = css`
    font-family: monospace;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  `

  const submitStyle = css`
    font-family: monospace;
    width: 100%;
    padding: 10px;
    background-color: green;
  `

  const handleSubmit = e => {
    e.preventDefault()

    fetch("https://brain-api.spinup.media/api/game/rankings", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        company,
        score,
      }),
    })

    close()
  }

  return (
    <>
      <Box
        className={css`
          position: fixed;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 20px;
          background-color: #1a1818;
          color: #fff;
          font-family: monospace;
          z-index: 222;

          ${isInserting
            ? `
            top: 0;
            overflow-y: scroll;
          `
            : `
            border-top: 1px solid #eee;
          `}
        `}
      >
        {!isInserting ? (
          <>
            <Text>
              You won in {(score / 1000).toFixed(2)} seconds! Do you want to
              insert your score in the rankings?
            </Text>

            <Box>
              <button
                className={buttonStyle}
                onClick={() => {
                  setIsInserting(true)
                }}
              >
                Yes
              </button>

              <button
                className={buttonStyle}
                onClick={() => {
                  close()
                }}
              >
                No
              </button>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                position: "fixed",
                top: "20px",
                right: "40px",
                fontSize: 3,
                zIndex: 111,
                cursor: "pointer",
              }}
              onClick={() => close()}
            >
              <FontAwesomeIcon icon={faTimes} color="#fff" />
            </Box>

            <Box
              className={css`
                max-width: 300px;
              `}
            >
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Nome"
                  maxLength={30}
                  className={inputStyle}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />

                <input
                  type="text"
                  placeholder="Società (opzionale)"
                  maxLength={30}
                  className={inputStyle}
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />

                <button type="submit" disabled={!name} className={submitStyle}>
                  Send
                </button>
              </form>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default InsertRanking
